









import { Component, Vue } from 'vue-property-decorator'
import ChampSkillScore from "@/components/stats/lol/ScoreTracer/PlayerScore/ChampSkillScore.vue"
import UniqueChamps from "./UniqueChamps.vue"
import WnrGraph from './WnrGraph.vue'

@Component({
    components: {ChampSkillScore, UniqueChamps, WnrGraph}
})
export default class AiInfo extends Vue {
}
