








import { Component, Vue } from 'vue-property-decorator'
import ChampSkillScore from './PlayerScore/ChampSkillScore.vue'    
import DraftScore from './PlayerScore/DraftScore.vue'    
import ImpactScore from './PlayerScore/ImpactScore.vue'    

@Component({
    components: {ChampSkillScore, DraftScore, ImpactScore}
})
export default class PlayerGrades extends Vue {}
