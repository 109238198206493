














    import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
    import { ILolMatch, ILolMatchParticipant, ISummoner } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
    import { COLORS } from '@/core/helpers/color.types';
    import { Timing } from '@/core/helpers/Timing';
    import { LolModuleGetterTypes } from '@/store/modules/lol/types';
    import { StoreNamespace } from '@/store/types';
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { Getter } from 'vuex-class';
    import Match from './match/Match.vue'
    import MatchMobileCardVue from './match/MatchMobileCard.vue'

    @Component({
        components: { Match, MatchMobileCardVue },
        enums: { COLORS }
    })
    export default class Matches extends Vue {
        @Prop() private readonly matches!: Array<ILolMatch> | undefined;
        @Prop() private readonly screenWidth!: number;
        
        private readonly mobileBreakpoint: number = LeagueOfLegendsConstants.mobileBreakpointPx;
        private player!: ILolMatchParticipant;

        @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
        private readonly summoner!: ISummoner;

        private wasRemake(match: ILolMatch): boolean {
            for (const participant of match.game_account_matches) {
                if (participant.remake) {
                    return true;
                }
            }
            return false;
        }

        private getIngameSummoner(gameInfo: ILolMatch): any {
            if (!gameInfo)
                return undefined;
            for (const player of gameInfo.data.info.participants) {
                if (player.puuid === this.summoner.puuid) {
                    return player;
                }
            }
        }

        private matchDateStr(match: ILolMatch): string {
            const timestamp = match.data.info.gameCreation;
            return Timing.localTimePmStr(timestamp);
        }



        get isMobileScreen(): boolean {
            return this.screenWidth < this.mobileBreakpoint;
        }
        
}
