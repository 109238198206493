








































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISkillFeature, IUniqueChampionData} from "./types"
import { LolDataDragonAssetsApi } from '@/services/RiotAssets/LolDataDragonAssetsApi';
import FeatureInfo from "./FeatureInfo.vue"
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends';
import { COLORS } from '@/core/helpers/color.types';
import { ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import ChampionScores from '@/components/stats/lol/ScoreTracer/UniqueChamps/ChampionScores.vue'
import DotSeparatedText from "@/components/Shared/DotSeparatedText.vue"

@Component({
    components: {FeatureInfo, ChampionScores, DotSeparatedText},
    enums: {COLORS, ICON}
})
export default class ChampSheet extends Vue {
    @Prop({ type: String, required: true}) private readonly title!: boolean;
    @Prop({required: true}) private readonly champData!: IUniqueChampionData;

    private show: boolean = false;
    
    champIconUri(championName: string) {
        return LolDataDragonAssetsApi.championIconUri(championName);
    }

    get subtitleItems(): {icon?: string | undefined, text: string}[] {
        return [
            {text: this.champData.champion_name},
            {text: this.champData.individual_position},
        ]
    }

    get skill(): number {
        return this.champData.avg_champion_score_label;
    }

    get wnr(): number {
        return LeagueOfLegendsUtils.formatWnr(this.champData.wnr);
    }

    get featuresArray(): ISkillFeature[] {
        return this.champData.features;
    }
}

