import { IGraphRecordData, IMovingAvgDataInputRecord } from "@/calculations/common/MovingAvg";
import { IGraphMatchData } from "@/core/games/leagueOfLegends/leagueOfLegends.types";

export class WnrGraphRecord implements IMovingAvgDataInputRecord, IGraphRecordData {

    public champion_name: string;
    public win: boolean;
    private value: number = -1;
    private initialized: boolean = false;

    constructor(data: IGraphMatchData) {
        this.win = data.win;
        this.champion_name = data.champion_name;
    }
    
    isPositive(): boolean {
        return this.win;
    }

    getLabel(): string {
        return this.champion_name;
    }
    
    getValue(): number {
        return this.value;
    }

    setValue(value: number): void {
        this.value = value;
        this.initialized = true;
    }

    isInitialized(): boolean {
        return this.initialized;
    }
}