import { render, staticRenderFns } from "./ChampionScores.vue?vue&type=template&id=0ae71942&"
import script from "./ChampionScores.vue?vue&type=script&lang=ts&"
export * from "./ChampionScores.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VIcon,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemSubtitle,VListItemTitle,VRating})
