import { LeagueOfLegendsConstants } from "@/calculations/leagueOfLegends/constans";
import { CHAMPION_SCORE, COMPRASION, ICON } from "@/core/games/leagueOfLegends/leagueOfLegends.enums";
import { COLORS } from "@/core/helpers/color.types";

export class LeagueOfLegendsUtils {
    private constructor() {}
    
    public static mapValueToLabel<T, N>(value: T, map: Map<N, Array<T | undefined>>): N | undefined {
        let label: N | undefined = undefined;

        for (const keyValuePair of map) {
            const A: any = keyValuePair[1][0];
            const B: any = keyValuePair[1][1];
            label = keyValuePair[0];

            if (A === undefined && B === undefined)
                return label;
            else if (B === undefined) {
                if (value >= A)
                    return label;
            }
            else if (A === undefined) {
                if (value <= B)
                    return label;
            }
            else {
                if (value >= A && value <= B)
                    return label;
            }
        }

        return label;
    }

    public static formatWnr(wnr: number): number {
        return Math.round(wnr * 100);
    }
    
    public static formatKpp(kpp: number) {
        return Math.round(kpp * 100);
    }

    public static scoreIcon(score: CHAMPION_SCORE): ICON | undefined {
        return LeagueOfLegendsConstants.scoreToIconMap.get(score);
    }

    public static getComprasionIcon(value1: number, value2: number) {
        if (value1 > value2)        return LeagueOfLegendsConstants.comprasionIconMap.get(COMPRASION.MORE);
        else if (value1 < value2)   return LeagueOfLegendsConstants.comprasionIconMap.get(COMPRASION.LESS);
        return LeagueOfLegendsConstants.comprasionIconMap.get(COMPRASION.EQUAL);
    }

    public static getIconColor(icon: ICON): COLORS {
        if (icon === ICON.MORE)           return COLORS.PRIMARY;
        else if (icon === ICON.LESS)    return COLORS.PRIMARY;
        return COLORS.PRIMARY;
    }
}
