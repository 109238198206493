export interface IGraphRecordData {
    getLabel: () => string,
    getValue: () => number,
    isPositive: () => boolean,
}

export interface AverageData {
    positivePercentage: number,
    average: Array<IGraphRecordData>
}

export interface IMovingAvgDataInputRecord {
    getLabel: () => string,
    isPositive: () => boolean,
    setValue: (value: number) => void,
    isInitialized: () => boolean
}

export class MovingAvg {

    public static lastMovingAvgRange: number = 0;
    
    private constructor() {}

    public static movingAvgCalc(arrayData: Array<IMovingAvgDataInputRecord & IGraphRecordData>, movingAvgRange: number): Array<IGraphRecordData> {
        let positiveSum = 0;
        MovingAvg.lastMovingAvgRange = movingAvgRange;

        for (let i = arrayData.length - 1; i >= 0; --i) {
            const recordInfo: IMovingAvgDataInputRecord = arrayData[i];
            if (i < arrayData.length - movingAvgRange) {
                if (recordInfo.isPositive())  positiveSum++;
                let singleAvgWinSum = 0;
                for (let j = i; j < arrayData.length && j < i + movingAvgRange; ++j) {
                    if (arrayData[j].isPositive()) singleAvgWinSum++;
                }
                arrayData[i].setValue(singleAvgWinSum / movingAvgRange)
            }
        }

        return arrayData.filter(el => el.isInitialized());
    }

}
