

















































import { Component, Vue, Prop } from 'vue-property-decorator'
import MatchMixin from './MatchMixin.vue'
import ItemsNSpells from './ItemsNSpells.vue'
import Label from './Label.vue'
import Details from './Details.vue'
import MatchHeader from "@/components/stats/lol/ScoreTracer/match/MatchHeader.vue"
import PlayerMatchStats from "./PlayerMatchStats.vue";
import TeamAnalysis from "./TeamAnalysis.vue"

@Component({
    components: {ItemsNSpells, Label, Details, MatchHeader, PlayerMatchStats, TeamAnalysis},
    mixins: [MatchMixin]
})
export default class Match extends Vue {
    private dialog: boolean = false;
    private analysis: boolean = false;
    get analysisBtnText(): number {
        return this.analysis ? 0 : 1;
    }
}
