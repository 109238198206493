















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISkillFeature } from "./types"
import FeatureInfo from "./FeatureInfo.vue"
import { COLORS } from '@/core/helpers/color.types';
import { CHAMPION_SCORE, ICON, RATING_ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import { LolMatchTools } from '@/calculations/leagueOfLegends/LolMatchTools';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';

@Component({
    components: {FeatureInfo},
    enums: {COLORS, ICON, RATING_ICON}
})
export default class ChampionScores extends Vue {
    @Prop({required: true}) private readonly skill!: CHAMPION_SCORE;
    @Prop({required: true}) private readonly featuresArray!: ISkillFeature[];
    @Prop({required: true}) private readonly wnr!: string;

    get avgFeatureScore(): string {
        if (this.featuresArray.length === 0)    return '';
        let scoreSum = 0
        for (let feature of this.featuresArray)
            scoreSum += feature.champion_score;
        const score = LolMatchTools.formatAvgFeatureScore(scoreSum, this.featuresArray.length);
        return score ? `(${score})` : '';
    }

    get maxScore(): number {
        return LeagueOfLegendsConstants.maxScoreRating;
    }
}

