import { CHAMPION_SCORE, COMPRASION, GRAPH_TREND, ICON, IndividualPosition, PLAYER_IMPACT, SCORE_STR_LABEL, URI_POSITION } from "@/core/games/leagueOfLegends/leagueOfLegends.enums";
import { LeagueOfLegendsRegions } from "@/core/games/leagueOfLegends/leagueOfLegends.types";

export class LeagueOfLegendsConstants {

    private LeagueOfLegendsConstants() {}

    public static readonly mobileBreakpointPx: number = 500;
    public static readonly maxScoreRating = 5;

    public static positionTranslationsMap = new Map<IndividualPosition, URI_POSITION>()
    public static rolesTranslationsMap = new Map<string, string>([
        ["PLATINUM", "Plat"]
    ])

    public static teamworkScoreToLabelMap = new Map<CHAMPION_SCORE, Array<number | undefined>>([
        [CHAMPION_SCORE.POOR, [undefined, 1.2]],
        [CHAMPION_SCORE.GOOD, [1.2, 2.8]],
        [CHAMPION_SCORE.EXCELLENT, [2.8, undefined]],
        [CHAMPION_SCORE.NOT_AVAILABLE, [undefined, undefined]],
    ])

    public static impactToLabelMap = new Map<PLAYER_IMPACT, Array<number | undefined>>([
        [PLAYER_IMPACT.TROLL, [undefined, 0.8]],
        [PLAYER_IMPACT.GG, [0.8, 1.2]],
        [PLAYER_IMPACT.CARRY, [1.2, 1.4]],
        [PLAYER_IMPACT.SMURF, [1.4, undefined]],
        [PLAYER_IMPACT.UNKNOWN, [undefined, undefined]],
    ])

    public static graphTrendRangesMap = new Map<GRAPH_TREND, Array<number | undefined>>([
        [GRAPH_TREND.DECLININING, [undefined, 0.95]],
        [GRAPH_TREND.RISING, [1.05, undefined]],
        [GRAPH_TREND.STABLE, [undefined, undefined]],
    ]);

    public static readonly namedRegions: Array<[string, LeagueOfLegendsRegions]> = [
        ['EUNE', LeagueOfLegendsRegions.EUN1],
        ['EUW', LeagueOfLegendsRegions.EUW1],
        ['NA', LeagueOfLegendsRegions.NA1],
        ['Japan', LeagueOfLegendsRegions.JP1],
        ['Brazil', LeagueOfLegendsRegions.BR1],
        ['Korea', LeagueOfLegendsRegions.KR],
        ['LAN', LeagueOfLegendsRegions.LA1],
        ['LAS', LeagueOfLegendsRegions.LA2],
        ['Oceania', LeagueOfLegendsRegions.OC1],
        ['Russia', LeagueOfLegendsRegions.RU],
        ['Turkey', LeagueOfLegendsRegions.TR1]
    ]

    public static readonly comprasionIconMap = new Map<number, ICON>([
        [COMPRASION.EQUAL, ICON.EQUAL],
        [COMPRASION.MORE, ICON.MORE],
        [COMPRASION.LESS, ICON.LESS],
    ])

    public static readonly scoreToIconMap = new Map<CHAMPION_SCORE, ICON>([
        [CHAMPION_SCORE.NOT_AVAILABLE, ICON.SAD],
        [CHAMPION_SCORE.POOR, ICON.DOG],
        [CHAMPION_SCORE.GOOD, ICON.CHECK],
        [CHAMPION_SCORE.EXCELLENT, ICON.CHECK_ALL],
    ])
    
    public static readonly skillScoreToLabelMap = new Map<CHAMPION_SCORE, Array<number | undefined>>([
        [CHAMPION_SCORE.POOR, [undefined, 1.6]],
        [CHAMPION_SCORE.GOOD, [1.6, 2.4]],
        [CHAMPION_SCORE.EXCELLENT, [2.4, 3]],
        [CHAMPION_SCORE.NOT_AVAILABLE, [undefined, undefined]],
    ])

    public static readonly skillLabelToScoreMap = new Map<SCORE_STR_LABEL, CHAMPION_SCORE>([
        [SCORE_STR_LABEL.poor, CHAMPION_SCORE.POOR],
        [SCORE_STR_LABEL.good, CHAMPION_SCORE.GOOD],
        [SCORE_STR_LABEL.excellent, CHAMPION_SCORE.EXCELLENT]
    ])

    public static readonly individualPositionToUriPositionMap = new Map<IndividualPosition, URI_POSITION>([
        [IndividualPosition.UTILITY, URI_POSITION.SUPPORT],
        [IndividualPosition.BOTTOM, URI_POSITION.BOT],
        [IndividualPosition.MIDDLE, URI_POSITION.MID],
        [IndividualPosition.TOP, URI_POSITION.TOP],
        [IndividualPosition.JUNGLE, URI_POSITION.JUNGLE],
        [IndividualPosition.UNKNOWN, URI_POSITION.UNKNOWN],
        [IndividualPosition.MIXED, URI_POSITION.MIXED],
    ])
}
