










import { Component, Vue, Prop } from 'vue-property-decorator'
import { ILolMatch, ILolMatchParticipant } from '@/core/games/leagueOfLegends/leagueOfLegends.types'
import { COLORS } from '@/core/helpers/color.types'
import { ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';

interface listItem {
    icon?: ICON | undefined,
    text: string
}

@Component({
    enums: {COLORS, ICON},
})
export default class DotSeparatedText extends Vue {
    @Prop({required: true}) private readonly items!: listItem[];
}
