













import InfoSquareMixin from './InfoSquareMixin.vue'
import { Component, Vue, Prop} from 'vue-property-decorator'
import { LolModuleGetterTypes } from '@/store/modules/lol/types';
import { StoreNamespace } from '@/store/types';
import { Getter } from 'vuex-class';
import { IPositionCharacteristic, IRankedInfo } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import { IndividualPosition } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    mixins: [InfoSquareMixin]
})
export default class RoleSquare extends Vue {
    private readonly defaultLeague: string = "Gold";

    @Prop() private readonly dataInfo!: IPositionCharacteristic;

    @Getter(LolModuleGetterTypes.SUMMONER_RANK_SOLO, { namespace: StoreNamespace.Lol })
    private readonly soloRank!: IRankedInfo;

    leagueStr(league: string) {
        let leagueCase = league.toLowerCase();
        leagueCase = leagueCase.slice(0, 1).toUpperCase() + leagueCase.slice(1); 
        return leagueCase;
    }
    
    positionStr(role: IndividualPosition) {
        return LeagueOfLegendsConstants.individualPositionToUriPositionMap.get(role);
    }

    roleUri(role: IndividualPosition): string {
        let league: string | undefined = "";    
        const rank = this.soloRank;

        if (LeagueOfLegendsConstants.rolesTranslationsMap.has(rank.tier))
            league = LeagueOfLegendsConstants.rolesTranslationsMap.get(rank.tier)
        if (!rank)
            league = this.defaultLeague;
        else
            league = this.leagueStr(rank.tier);

        return `ranked-positions/Position_${league}-${this.positionStr(role)}.png`
    }
    
    get mainRole(): IndividualPosition {
        if (this.dataInfo)
            if (this.dataInfo.tag)
                return this.dataInfo.tag;
            else return IndividualPosition.MIXED;
        else return IndividualPosition.UNKNOWN;
    }

    get isUnkrankedOrMixed(): boolean {
        return this.mainRole === IndividualPosition.MIXED || this.mainRole === IndividualPosition.UNKNOWN;
    }

}
