









import { Component, Vue, Prop } from 'vue-property-decorator'
import { ILolMatchParticipant } from '@/core/games/leagueOfLegends/leagueOfLegends.types'
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import { LolMatchTools } from '@/calculations/leagueOfLegends/LolMatchTools';
import TeamDraft from '@/components/stats/lol/ScoreTracer/match/TeamStats/TeamDraft.vue'
import Teamwork from '@/components/stats/lol/ScoreTracer/match/TeamStats/Teamwork.vue'
import TeamChampPerformance from '@/components/stats/lol/ScoreTracer/match/TeamStats/TeamChampPerformance.vue'
import { CHAMPION_SCORE, DRAFT_RESULT } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';

@Component({
    components: {TeamDraft, TeamChampPerformance, Teamwork},
})
export default class TeamAnalysis extends Vue {
    @Prop({required: true}) private readonly avgTeamPerformance!: {ally: number, enemy: number};
    @Prop({required: true}) private readonly teamDraftScores!: [number, number];
    @Prop({required: true}) private readonly teamworkScores!: [number, number];
    @Prop({required: true}) private readonly player!: ILolMatchParticipant;

    private getComprasionPhrase(a: number, b: number): DRAFT_RESULT {
        return LolMatchTools.rateDraftScores([a, b])
    }

    private getComprasionIcon(value1: number, value2: number) {
        return LeagueOfLegendsUtils.getComprasionIcon(value1, value2);
    }

    get draftScore() {
        return LolMatchTools.rateDraftScores(this.teamDraftScores);
    }

    get performanceRating(): CHAMPION_SCORE | undefined {
        return LeagueOfLegendsUtils.mapValueToLabel(this.avgTeamPerformance.ally, LeagueOfLegendsConstants.skillScoreToLabelMap);
    }

    get summonerName(): string {
        return this.player ? this.player.summonerName : "";
    }

    get teamworkScore(): CHAMPION_SCORE | undefined {
        return LeagueOfLegendsUtils.mapValueToLabel(this.teamworkScores[0], LeagueOfLegendsConstants.teamworkScoreToLabelMap)
    }
}
