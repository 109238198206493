


























import { Component, Vue, Prop } from 'vue-property-decorator'
import MatchMixin from './MatchMixin.vue'
import ItemsNSpells from './ItemsNSpells.vue'
import Label from './Label.vue'
import Details from './Details.vue'
import MatchHeader from "@/components/stats/lol/ScoreTracer/match/MatchHeader.vue"
import PlayerMatchStats from "./PlayerMatchStats.vue";
import TeamAnalysis from "./TeamAnalysis.vue"
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"
import { COLORS } from '@/core/helpers/color.types'

enum ANALYSIS_BTN_TEXT {
    CLOSE,
    SHOW
}

@Component({
    components: {ItemsNSpells, Label, Details, MatchHeader, PlayerMatchStats, TeamAnalysis, BaseButtonRounded},
    mixins: [MatchMixin],
    enums: { COLORS }
})
export default class Match extends Vue {
    @Prop({required: true}) private readonly wasRemake!: boolean;
    @Prop({required: true}) private readonly isMobileScreen!: boolean;

    private isActive: boolean = false;
    private screenWidth: number = 500;

    private scrollByValue: number = 300;
    private showDetails: boolean = false;
    private showLoader: boolean = false;
    private analysis: boolean = false;
    private detailsReady: boolean = false;

    private onDetailsClick(withUpScroll: boolean = false) {
        if (!this.showDetails) {
            window.scrollBy({top: this.scrollByValue, behavior: 'smooth'})
        }
        else if (withUpScroll) {
            window.scrollBy({top: -this.scrollByValue, behavior: 'smooth'})
        }
        this.showDetails = !this.showDetails
    }
    
    get analysisBtnText(): number {
        return this.analysis ? ANALYSIS_BTN_TEXT.CLOSE : ANALYSIS_BTN_TEXT.SHOW;
    }


}
