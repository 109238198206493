













import {LolDataDragonAssetsApi} from '@/services/RiotAssets/LolDataDragonAssetsApi'
import InfoSquareMixin from './InfoSquareMixin.vue'
import { Component, Vue, Prop} from 'vue-property-decorator'
import { IChampionCharacteristic } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { MAIN_CHAMP_ENUM } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    mixins: [InfoSquareMixin]
})
export default class ChampSquare extends Vue {
    @Prop() private readonly dataInfo!: IChampionCharacteristic;

    get mainChamp(): string {
        if (this.dataInfo)
            if (this.dataInfo.championName)
                return this.dataInfo.championName;
            else return MAIN_CHAMP_ENUM.MIXED;
        else return MAIN_CHAMP_ENUM.UNKNOWN;
    }

    private getChampionUri(): string {
        if (this.mainChamp != MAIN_CHAMP_ENUM.UNKNOWN)
            return LolDataDragonAssetsApi.championIconUri(this.mainChamp);
        return "";
    }
}
