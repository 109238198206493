

import {KDA, TeamsPuuids, PlayerMetadata, LolMatchTools} from '@/calculations/leagueOfLegends/LolMatchTools'
import { Component, Vue, Prop } from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import { ILolMatch, ILolMatchParticipant, ISummoner } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { Timing } from '@/core/helpers/Timing';

@Component({})
export default class MatchMixin extends Vue {
    @Prop() private readonly matchData!: ILolMatch;
    @Prop({required: true}) private readonly player!: ILolMatchParticipant;

    private showDetails: any = null;

    private teamMvpId(teamIds: number[]): number | undefined{
      let bestPlayerId: number | undefined = undefined;
      let bestValue: number = 0
      for (const allyId of teamIds) {
        const playerMeta: PlayerMetadata = this.playersPerformance.get(allyId);

        if (playerMeta.performance && playerMeta.performance > bestValue) {
          bestValue = playerMeta.performance;
          bestPlayerId = allyId;
        }
      }
      return bestPlayerId;
    }
    
    get detailsLabel(): string {
        return this.showDetails ? 'close' : 'details';
    }

    get teamworkScores(): [number, number] {
        const allyKDA: KDA = LolMatchTools.getTeamKdaSum(this.teamDividedIds.allyTeam, this.matchData);
        const enemyKDA: KDA = LolMatchTools.getTeamKdaSum(this.teamDividedIds.enemyTeam, this.matchData);

        return [
            allyKDA.assists / allyKDA.kills,
            enemyKDA.assists / enemyKDA.kills
        ]
    }
    get teamDraftScores(): any {
        
        const teamsPuuids: TeamsPuuids = this.teamDividedIds;

        return [LolMatchTools.sumDraftScore(teamsPuuids.allyTeam, this.playersPerformance), LolMatchTools.sumDraftScore(teamsPuuids.enemyTeam, this.playersPerformance)];
    }

    get summonerImpact(): number {
        const summonerPerformance: PlayerMetadata | undefined = this.playersPerformance.get(this.player.participantId);
        
        const summonerTeamPerf = this.avgTeamPerformance.ally;
        if (!summonerPerformance || !summonerPerformance.performance || !summonerTeamPerf)  return 0;
        return summonerPerformance.performance / summonerTeamPerf;
    }
    get avgTeamPerformance(): {ally: number, enemy: number} {
        return {
            
            ally: LolMatchTools.calculateAvgTeamPerformance(this.teamDividedIds.allyTeam, this.playersPerformance),
            
            enemy: LolMatchTools.calculateAvgTeamPerformance(this.teamDividedIds.enemyTeam, this.playersPerformance)
        }
    }
    get teamDividedIds(): TeamsPuuids {
        const puuidsArray: TeamsPuuids = {
            allyTeam: [],
            enemyTeam: []
        }
        
        const isWin = this.player.win;

        
        for (const player of this.matchData.data.info.participants) {
            if (player.win === isWin)
                puuidsArray.allyTeam.push(player.participantId);
            else
                puuidsArray.enemyTeam.push(player.participantId);
        }

        return puuidsArray;
    }
    get playersPerformance(): any {
        return LolMatchTools.calculatePlayerPerformanceMap(this.matchData);
    }
    get kda(): string {
        const ingameSummoner = this.player;
        return `${ingameSummoner.kills} / ${ingameSummoner.deaths} / ${ingameSummoner.assists}`
    }
}
