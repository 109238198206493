
export default {
    data() {
        return {
            height: 100,
            cols: 12,
            sm: 6,
            md: 4,
            baseCssClass: "pl-4 px-4 d-flex align-center rounded-lg text-truncate"
        }
    }
}
