



























import ScoreMixin from './ScoreMixin.vue'
import { Component, Vue } from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import { IScore, ISummoner } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import { CHAMPION_SCORE, ICON, RATING_ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from '@/components/Core/Tooltip.vue';
import { COLORS } from '@/core/helpers/color.types';


@Component({
    components: {Tooltip},
    mixins: [ScoreMixin],
    enums: { COLORS, RATING_ICON }
})
export default class ChampSkillScore extends Vue {
    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner;
    
    @Getter(LolModuleGetterTypes.PLAYER_SCORE, { namespace: StoreNamespace.Lol })
    private readonly score!: IScore;

    private getSkillIcon(skillScore: CHAMPION_SCORE): ICON | undefined{
        return LeagueOfLegendsUtils.scoreIcon(skillScore)
    }
  
    get maxScore(): number {
        return LeagueOfLegendsConstants.maxScoreRating;
    }

    get championSkillScore(): CHAMPION_SCORE | undefined {
        return this.champScore;
    }

    get champScore(): number {
        const globalScore = this.score;
        if (!globalScore || !globalScore.individual_champion_skill_score) {
            return -1;
        }
        return globalScore.individual_champion_skill_score.label;
    }
        
    get summonerName(): string {
        return this.summoner ? this.summoner.name : "";
    }
}
