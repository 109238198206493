



















































import { LolStoreModule } from "@/store"
import { Component, Vue, Watch } from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import { Getter } from "vuex-class";
import { ISummoner, LeagueOfLegendsRegions } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { RouteNames } from "@/router/types";
import { LeagueOfLegendsConstants } from "@/calculations/leagueOfLegends/constans";
import { COLORS } from "@/core/helpers/color.types"

@Component({
    enums: {COLORS}
})
export default class SearchSummoner extends Vue {
    private readonly lolStatsRoutePrefix: string = "/stats/lol";
    private summonerNameInput: string = ""
    private servers: Array<[string, LeagueOfLegendsRegions]> = LeagueOfLegendsConstants.namedRegions;

    private activeServer = this.servers[0];

    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner

    @Getter(LolModuleGetterTypes.LAST_SEARCHED_REGION, { namespace: StoreNamespace.Lol })
    private readonly lastSearchedRegion!: string

    get summonerName(): string {
        if (!this.summoner) return "";
        return this.summoner.name;
    }

    mounted() {
        if (this.$route.params && this.$route.params.name === this.summonerName && this.$route.params.region === this.lastSearchedRegion) {
            return;
        }
        else if (this.$route.params.name && this.$route.params.region) {
            this.fetchSummoner(this.$route.params.name, this.$route.params.region);
        }
    }

    searchSummoner(): void {
        console.log(this.$route.path);
        if (this.summonerNameInput.length <= 0) {
            if (this.$route.path !== this.lolStatsRoutePrefix)
                this.$router.push({path: this.lolStatsRoutePrefix});
            return;
        }
        this.$router.push({name: RouteNames.LolWelcome, params: {name: this.summonerNameInput, region: this.serverPlatform}});
    }

    changeServer(newServer: [string, LeagueOfLegendsRegions]): void {
        this.activeServer = newServer;
    }

    fetchSummoner(name: string, region: string) {
        const forced: number = 0;
        LolStoreModule.fetchSummoner({name, region, forced});
    }

    getServerByPlatform(platform: LeagueOfLegendsRegions): [string, LeagueOfLegendsRegions] {
        const server = this.servers.find((el: [string, LeagueOfLegendsRegions]) => {
            const elPlatform: LeagueOfLegendsRegions = el[1];
            return elPlatform === platform;
        });
        return server ? server : this.servers[0];
    }

    private onUriChangeParamsCheck(to: any, from: any): boolean {
        const notEmptyParams = to.params.name && from.params.name && to.params.region && from.params.region;
        return !(notEmptyParams && to.params.name === from.params.name && to.params.region === from.params.region);
    }

    get serverName(): string {
        return this.activeServer[0];
    }

    get serverPlatform(): string {
        return this.activeServer[1];
    }
    
    @Watch('$route')
    onRouteChange(to: any, from: any) {
        if (!this.onUriChangeParamsCheck(to, from)) return;

        this.fetchSummoner(to.params.name, to.params.region);
        if (to.params.name && to.params.region) {
            this.summonerNameInput = to.params.name;
            this.activeServer = this.getServerByPlatform(to.params.region as LeagueOfLegendsRegions);
        }
    }
}
