






import { Component, Vue, Prop } from 'vue-property-decorator'
import { LabelArrayItem } from '@/calculations/leagueOfLegends/LolMatchTools';
import { LOL_PARTICIPANT_LABEL } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';

@Component({})
export default class PlayerLabel extends Vue {
    @Prop({required: true}) private readonly label!: LabelArrayItem;
    @Prop({required: false, default: () => false}) private readonly small!: boolean;

    get isWithPayload() {
        switch (this.label.type) {
            case LOL_PARTICIPANT_LABEL.DOUBLE_KILLS:
                return true;
            default:
                return false;
        }
    }
}
