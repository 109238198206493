


























import { Component, Vue } from 'vue-property-decorator'
import SummonerDemo from './ScoreTracer/SummonerDemo.vue'
import SearchSummoner from './ScoreTracer/Search.vue'
import Matches from './ScoreTracer/Matches.vue'
import WnrGraph from './ScoreTracer/WnrGraph.vue'
import PlayerScore from './ScoreTracer/PlayerScore.vue';
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import UniqueChamps from "./ScoreTracer/UniqueChamps.vue"
import { ILolMatch, ISummoner } from '@/core/games/leagueOfLegends/leagueOfLegends.types'
import CircularLoading from '@/components/Loading/CircularLoading.vue'
import { COLORS } from "@/core/helpers/color.types"
import ChampSkillScore from "@/components/stats/lol/ScoreTracer/PlayerScore/ChampSkillScore.vue"
import AiInfo from './ScoreTracer/AiInfo.vue'
import NotFoundSummoner from "@/components/stats/lol/ScoreTracer/NotFoundSummoner.vue"

@Component({
    name: 'WelcomeInfo',
    components: {
        SummonerDemo,
        SearchSummoner,
        Matches,
        WnrGraph,
        PlayerScore,
        UniqueChamps,
        ChampSkillScore,
        CircularLoading,
        AiInfo,
        NotFoundSummoner
    },
    enums: {COLORS}
})
export default class ScoreTracer extends Vue {
    private bgChampion: string = "";
    private screenWidth: number = window.innerWidth;
    private readonly mobileBreakpoint: number = 1920;

    @Getter(LolModuleGetterTypes.IS_LOADING, { namespace: StoreNamespace.Lol })
    private readonly isLoading!: boolean
    
    @Getter(LolModuleGetterTypes.MATCHES, { namespace: StoreNamespace.Lol })
    private readonly matches!: Array<ILolMatch>
    
    @Getter(LolModuleGetterTypes.SUMMONER_FOUND, { namespace: StoreNamespace.Lol })
    private readonly summonerFound!: boolean
    
    @Getter(LolModuleGetterTypes.IS_READY, { namespace: StoreNamespace.Lol })
    private readonly statusReady!: boolean
    
    @Getter(LolModuleGetterTypes.PROFILE_ICON, { namespace: StoreNamespace.Lol })
    private readonly profileIcon!: number
    
    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner;

    @Getter(LolModuleGetterTypes.LAST_SEARCHED_REGION, { namespace: StoreNamespace.Lol })
    private readonly lastSearchedRegion!: string;

    private created() {
        if (this.summonerFound) {
            const isSameSummonerSearch: boolean = (!this.$route.params.name && !this.$route.params.name) || (this.$route.params && this.$route.params.name === this.summoner.name && this.$route.params.region === this.lastSearchedRegion);
            if (isSameSummonerSearch)
                this.$router.push({params: {name: this.summoner.name, region: this.lastSearchedRegion}});
        }
        window.addEventListener('resize', this.setCurrentWidth);
    }

    get emptyMatches(): boolean {
        return this.matchesByTimestamp.length < 1;
    }

    get matchesByTimestamp(): ILolMatch[] {
        return this.matches.sort((a: ILolMatch, b: ILolMatch) => {
            if (a.data.info.gameCreation < b.data.info.gameCreation)
                return 1;
            else return -1;
        });
    }

    get isMobileScreen(): boolean {
        return this.screenWidth < this.mobileBreakpoint;
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.setCurrentWidth);
    }

    setCurrentWidth() {
        this.screenWidth = window.innerWidth;
    }
}
