















































import ItemsNSpells from "./ItemsNSpells.vue"
import {LolDataDragonAssetsApi} from "@/services/RiotAssets/LolDataDragonAssetsApi"
import { Component, Vue, Prop } from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import {KDA} from "@/calculations/leagueOfLegends/LolMatchTools";
import { DetailsPlayerInfo } from "./Details.vue";
import { LeagueOfLegendsUtils } from "@/core/games/leagueOfLegends/leagueOfLegends"
import { LeagueOfLegendsConstants } from "@/calculations/leagueOfLegends/constans";
import { CHAMPION_SCORE, ICON, PLAYER_IMPACT, RATING_ICON } from "@/core/games/leagueOfLegends/leagueOfLegends.enums";
import { RouteNames } from "@/router/types";
import { ISummoner } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { COLORS } from "@/core/helpers/color.types";
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {ItemsNSpells, Tooltip},
    enums: {ICON, COLORS, RATING_ICON}
})
export default class SummonerMatchDetails extends Vue {
    @Prop() private readonly player!: DetailsPlayerInfo;
    @Prop() private readonly avgTeamPerf!: number;
    @Prop() private readonly teamKda!: KDA;
    
    @Getter(LolModuleGetterTypes.LAST_SEARCHED_REGION, { namespace: StoreNamespace.Lol })
    private readonly lastRegion!: any

    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner

    private fontSize: string = "0.8em";
    private size: number = 22;
    
    private summonerClickHandler(): void {
        let region = this.$route.params.region ? this.$route.params.region : this.lastRegion;
        this.$router.push({name: RouteNames.LolWelcome, params: {name: this.player.summonerName, region: region}});
    }

    private championIconUri() {
        return LolDataDragonAssetsApi.championIconUri(this.player.championName)
    }

    get maxRating(): number {
        return LeagueOfLegendsConstants.maxScoreRating;
    }

    get isSearchedPlayerClass(): string {
        return this.summoner.puuid === this.player.puuid ? 'primary' : 'transparent';
    }

    get Kpp() : string {
        return `${LeagueOfLegendsUtils.formatKpp((this.player.kills + this.player.assists) / this.teamKda.kills)}%`;
    }

    get impact(): PLAYER_IMPACT | undefined {
        return this.player.performance || this.player.performance == 0 ? LeagueOfLegendsUtils.mapValueToLabel(this.player.performance / this.avgTeamPerf as PLAYER_IMPACT, LeagueOfLegendsConstants.impactToLabelMap) : undefined;
    }
    
    get performanceRating(): CHAMPION_SCORE | undefined {
        return this.player.performance;
    }

    get avgFeatureScore() : string {
        return this.player.avgFeatureScore ? `(${this.player.avgFeatureScore})` : '';
    }
    
}
