













import SquareMixin from './InfoSquareMixin.vue'
import { Component, Vue} from 'vue-property-decorator'
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    mixins: [SquareMixin]
})
export default class LvlSquare extends Vue {}
