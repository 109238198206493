





















import ScoreMixin from './ScoreMixin.vue'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import { Component, Vue } from 'vue-property-decorator'
import { IScore, ISummoner } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import { CHAMPION_SCORE, ICON, SCORE_STR_LABEL } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    mixins: [ScoreMixin]
})
export default class DraftScore extends Vue {
    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner;
    
    @Getter(LolModuleGetterTypes.PLAYER_SCORE, { namespace: StoreNamespace.Lol })
    private readonly score!: IScore;

    get summonerName(): string {
        return this.summoner ? this.summoner.name : "";
    }

    get draftScoreLabel(): string {
        const globalScore = this.score;
        if (!globalScore || !globalScore.draft_score) {
            return "";
        }
        return globalScore.draft_score.label;
    }

    get draftScoreIcon(): string {
        return ICON.FLASK;
    }

    get draftScore(): CHAMPION_SCORE | undefined {
        const draftScore: SCORE_STR_LABEL = this.draftScoreLabel.toLowerCase() as SCORE_STR_LABEL;
        return LeagueOfLegendsConstants.skillLabelToScoreMap.get(draftScore);
    }
}
