























import SummonerDetails from "./SummonerDetails.vue"
import {KDA} from "@/calculations/leagueOfLegends/LolMatchTools";
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DetailsPlayerInfo } from "./Details.vue";
import { COLORS } from "@/core/helpers/color.types";
import { DETAILS_COLUMN } from "@/core/games/leagueOfLegends/leagueOfLegends.enums";

@Component({
    components: {SummonerDetails}
})
export default class TeamDetails extends Vue {
    @Prop() private readonly teamTag!: string;
    @Prop() private readonly teamMap!: Map<number, DetailsPlayerInfo>;
    @Prop() private readonly screenWidth!: number;
    @Prop() private readonly slicerActive!: boolean;
    @Prop() private readonly categories!: string[];
    @Prop() private readonly sliderValue!: number;
    
    private kdaString(kda: KDA) {
        return `${kda.kills} / ${kda.deaths} / ${kda.assists}`;
    }

    get categoriesPayload(): any {
        return [
            {column: DETAILS_COLUMN.SUMMONER, payload: {data : undefined, style: "width:65px;"}},
            {column: DETAILS_COLUMN.SCORE, payload: {data : undefined, style: "width:65px;"}},
            {column: DETAILS_COLUMN.BUILD, payload: {data : undefined, style: "width:195px;"}},
            {column: DETAILS_COLUMN.FIGHT, payload: {data : `${this.kdaString(this.teamKDA)}`, style: "width:227px;"}},
            {column: DETAILS_COLUMN.GOLD, payload: {data : `${this.teamTotalGold}`, style: "width:97px;"}},
        ]
    }

    get winColorClass(): string {
        for (const player of this.teamMap)
            return player[1].win ? `${COLORS.GREEN}--text` : `${COLORS.RED}--text`;
        return COLORS.SECONDARY;
    }

    get sliderActive(): boolean {
        return this.screenWidth == -1;
    }

    get teamKDA(): KDA {
        let result: KDA = {
            kills: 0,
            deaths: 0,
            assists: 0
        };
        for (const [participantId, playerInfo] of this.teamMap) {
            result.kills += playerInfo.kills;
            result.deaths += playerInfo.deaths;
            result.assists += playerInfo.assists;
        }

        return result;
    }

    get teamTotalGold(): number {
        let goldSum: number = 0;
        
        for (const player of this.teamMap) {
            goldSum += player[1].goldEarned;
        }

        return goldSum;
    }

    get AvgTeamPerf(): number {
        let divider: number = this.teamMap.size;
        let perfSum: number = 0;
        
        for (const player of this.teamMap) {
            if (player[1].performance)
                perfSum += player[1].performance;
            else divider--;
        }

        return divider < 1 ? 0 : perfSum / divider;
    }
}
