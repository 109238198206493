


















import { LolDataDragonAssetsApi } from "@/services/RiotAssets/LolDataDragonAssetsApi";
import EquipmentSlot from './EquipmentSlot.vue';
import { Component, Vue, Prop } from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class"
import { ILolMatchParticipant } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { IItemData, IPerkData, IRuneData, ISummonerSpell, ISummonerSPellsData } from "@/services/RiotAssets/types";
import { LolMatchTools } from "@/calculations/leagueOfLegends/LolMatchTools";

@Component({
    components: {EquipmentSlot}
})
export default class ItemsSpells extends Vue {
    @Prop({type: Number, default: 20, required: true}) private readonly size!: number;
    @Prop() private readonly player!: ILolMatchParticipant;

    @Getter(LolModuleGetterTypes.ITEMS_DATA, { namespace: StoreNamespace.Lol })
    private readonly itemsData!: Array<[string, IItemData]>;

    @Getter(LolModuleGetterTypes.SUMMONER_SPELLS, { namespace: StoreNamespace.Lol })
    private readonly summonerSpells!: ISummonerSPellsData

    @Getter(LolModuleGetterTypes.RUNES_DATA, { namespace: StoreNamespace.Lol })
    private readonly runesData!: IRuneData[]

    mounted() {
        this.$nextTick(() => {
            this.$emit('loaded');
        })
    }

    private getItemInfo(itemId :string) {
        return this.itemsData.find(el => el[0] == itemId);
    }

    private itemIconUri(item: number) {
        return LolDataDragonAssetsApi.itemIconUri(item);
    }

    private summonerSpellIconUri(spell: string) {
        return LolDataDragonAssetsApi.summonerSpellIconUri(spell);
    }

    get spellsToArray(): Array<[string, ISummonerSpell]> {
        return Object.entries(this.summonerSpells);
    }

    get items(): Array<number[]> {
        return [
            [this.player.item0, this.player.item1],
            [this.player.item2, this.player.item3],
            [this.player.item4, this.player.item5]         
        ];
    }

    get summonerSpell1Id(): string {
        return this.player.summoner1Id.toString();
    }

    get summonerSpell2Id(): string {
        return this.player.summoner2Id.toString();
    }

    get firstSpell(): ISummonerSpell | undefined {
        return LolMatchTools.getSummonerSpell(this.summonerSpell1Id, this.spellsToArray)
    }

    get secondSpell(): ISummonerSpell | undefined {
        return LolMatchTools.getSummonerSpell(this.summonerSpell2Id, this.spellsToArray)
    }

    get mainStyle(): IRuneData | undefined {
        return LolMatchTools.getMainStyle(this.player, this.runesData);
    }

    get mainRune(): IPerkData | undefined {
        return LolMatchTools.getMainPerk(this.player, this.mainStyle);
    }
}
