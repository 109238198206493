













import TeamDetails from "./TeamDetails.vue"
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ILolMatch, ILolMatchParticipant } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { PlayerMetadata, TeamsPuuids } from "@/calculations/leagueOfLegends/LolMatchTools";
import { DETAILS_COLUMN } from "@/core/games/leagueOfLegends/leagueOfLegends.enums";

export type DetailsPlayerInfo = PlayerMetadata & ILolMatchParticipant;

@Component({
    components: {TeamDetails}
})
export default class MatchDetails extends Vue {
    @Prop() private readonly playersPerfMap!: Map<number, PlayerMetadata>;
    @Prop() private readonly teams!: TeamsPuuids;
    @Prop() private readonly matchData!: ILolMatch;
    @Prop() private readonly screenWidth!: number;

    private readonly categories: Array<string> = [
        DETAILS_COLUMN.SUMMONER,
        DETAILS_COLUMN.SCORE,
        DETAILS_COLUMN.BUILD,
        DETAILS_COLUMN.FIGHT,
        DETAILS_COLUMN.GOLD
    ];

    private sliderValue: number = 0;
    private loaded: number = 0;
    private firstTeamLoaded: boolean = false;
    private secondTeamLoaded: boolean = false;

    private onChildLoaded(first: boolean) {
        if (first)  this.firstTeamLoaded = true;
        else this.secondTeamLoaded = true;
    }

    getTeamInfo(idsArray: Array<number>) : Map<number, DetailsPlayerInfo> {
        const resultMap = new Map();
        
        for (const id of idsArray) {
            if (this.playersPerfMap.has(id)) {
                const playerInfo = this.matchData.data.info.participants.find((player: any) => {
                    return player.participantId === id;
                });

                resultMap.set(id, {
                    ...this.playersPerfMap.get(id),
                    ...playerInfo
                });
            }
        }
        return resultMap
    }
    
    get isLoaded(): boolean {
        return this.firstTeamLoaded && this.secondTeamLoaded;
    }

    get allyTeamMap(): Map<number, DetailsPlayerInfo> {
        return this.getTeamInfo(this.teams.allyTeam); 
    }
    
    get enemyTeamMap(): Map<number, DetailsPlayerInfo> {
        return this.getTeamInfo(this.teams.enemyTeam);
    }
    
    get sliderActive(): boolean {
        return this.screenWidth == -1;
    }
}
