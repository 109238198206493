





















import ScoreMixin from './ScoreMixin.vue'
import { Component, Vue } from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import { IScore, ISummoner } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import { CHAMPION_SCORE, ICON, SCORE_STR_LABEL } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    mixins: [ScoreMixin]
})
export default class ImpactSkillScore extends Vue {
    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner;
    
    @Getter(LolModuleGetterTypes.PLAYER_SCORE, { namespace: StoreNamespace.Lol })
    private readonly score!: IScore;

    get summonerName(): string {
        return this.summoner ? this.summoner.name : ""
    }

    get impactLabel(): string {
        const globalScore = this.score;
        if (!globalScore || !globalScore.impact_score) {
            return "";
        }
        return globalScore.impact_score.label;
    }

    get impactIcon(): string {
        return ICON.FLASK;
    }

    get impactScore(): CHAMPION_SCORE | undefined {
        const impact: SCORE_STR_LABEL = this.impactLabel.toLowerCase() as SCORE_STR_LABEL;
        return LeagueOfLegendsConstants.skillLabelToScoreMap.get(impact);
    }
}
