

















import InfoSquareMixin from './InfoSquareMixin.vue'
import { Component, Vue, Prop} from 'vue-property-decorator'
import { IRankedInfo } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { RANK } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    mixins: [InfoSquareMixin]
})
export default class FlexRank extends Vue {
    @Prop() private readonly dataInfo!: IRankedInfo;
    get league(): string {
        return this.dataInfo ? `${this.dataInfo.tier}` : RANK.UNRANKED
    }
    
    get rank(): string {
        return this.dataInfo ? `${this.dataInfo.rank}` : '';
    }
    
    get rankDesc(): string {
        if (!this.dataInfo) return "";
        return `Flex: ${this.league} ${this.rank} ${this.dataInfo.wins}/${this.dataInfo.losses} - lp: ${this.dataInfo.leaguePoints}`;
    }
}
