
import { Component, Vue, Prop, Mixins} from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import { ISummoner } from '@/core/games/leagueOfLegends/leagueOfLegends.types';

@Component({})
export default class InfoSquareMixin extends Vue {
    @Prop() private readonly dataInfo!: any;
    @Prop({ type: Number, required: false, default: () => 20 }) private readonly size!: number;
    
    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner;

    private baseCssClass: string = "";
    
    leagueStr(league: string) {
        let leagueCase = league.toLowerCase();
        leagueCase = leagueCase.slice(0, 1).toUpperCase() + leagueCase.slice(1); 
        return leagueCase;
    }

    emblemUri(league: string) {
        return `ranked-emblems/Emblem_${this.leagueStr(league)}.png`;
    }
    
    get squareLabel(): string | undefined {
        if (this.dataInfo)
            return this.dataInfo.label;
        else return "";
    }

    get summonerName(): string {
        if (this.summoner)
            return this.summoner.name;
        return "";
    }
}
