















import { Component, Vue, Prop } from 'vue-property-decorator'
import { ICON, PLAYER_IMPACT } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip}
})
export default class KillParticipationInfo extends Vue {
    private readonly icon: ICON = ICON.SKULL;
    
    @Prop({required: true}) private readonly value!: PLAYER_IMPACT;
    @Prop({required: true}) private readonly summonerName!: string;
}
