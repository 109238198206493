











import { IGraphRecordData, MovingAvg } from "@/calculations/common/MovingAvg"
import GraphDesc from '@/components/stats/lol/ScoreTracer/WnrGraph/GraphDesc.vue'
import { Component, Vue } from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import { ICharacteristics, ISummoner, IWnrCharacteristic } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import GraphRecord from "@/components/stats/lol/ScoreTracer/WnrGraph/GraphRecord.vue"
import GraphHeader from "@/components/stats/lol/ScoreTracer/WnrGraph/GraphHeader.vue"
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends';
import { LeagueOfLegendsConstants } from "@/calculations/leagueOfLegends/constans";
import { GRAPH_TREND } from "@/core/games/leagueOfLegends/leagueOfLegends.enums";
import { WnrGraphRecord } from '@/components/stats/lol/ScoreTracer/WnrGraph/types'
import { COLORS } from "@/core/helpers/color.types";

@Component({
    components: {GraphDesc, GraphRecord, GraphHeader},
    enums: {COLORS}
})
export default class WnrGraph extends Vue {

    private readonly avgGraphLineClass = "avgWnrGraphLine";
    private matchAvgCalcRange: number = 20;
    private maxGraphHeight: number = 150;

    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner

    @Getter(LolModuleGetterTypes.CHARACTERISTIC, { namespace: StoreNamespace.Lol })
    private readonly characteristic!: ICharacteristics
    
    get wnrNumber(): number {
        const wnrChar = this.characteristic ? this.characteristic.wnr_characteristic : 0;
        if (wnrChar) 
            return LeagueOfLegendsUtils.formatWnr(wnrChar.avg_wnr);
        return 0;
    }

    get wnrRate(): string {
        const wnrChar = this.characteristic ? this.characteristic.wnr_characteristic : 0;
        if (wnrChar)
            return wnrChar.label;
        return "";
    }

    get wnrCharaceristic(): IWnrCharacteristic | undefined {
        return this.characteristic ? this.characteristic.wnr_characteristic : undefined;
    }

    get wnrGraphRecords(): IGraphRecordData[] {
        const wnrChar: IWnrCharacteristic | undefined = this.wnrCharaceristic;
        if (wnrChar) {
            const mappedRecords = wnrChar.matches.map(el => new WnrGraphRecord(el));
            return MovingAvg.movingAvgCalc(mappedRecords, 10).reverse();
        }
        return []
    }

    get trendInfo(): GRAPH_TREND | undefined {
        const trendValue = this.wnrGraphRecords[this.wnrGraphRecords.length - 1].getValue() / this.wnrCharaceristic!.avg_wnr;
        return LeagueOfLegendsUtils.mapValueToLabel(trendValue, LeagueOfLegendsConstants.graphTrendRangesMap);
    }

}
