














import { Component, Vue, Prop } from 'vue-property-decorator'
import Tooltip from "@/components/Core/Tooltip.vue"
import { COLORS } from "@/core/helpers/color.types"

@Component({
    components: {Tooltip},
})
export default class EquSlot extends Vue {
    @Prop({required: true}) private readonly itemUri!: string;
    @Prop({required: true}) private readonly itemId!: number;
    @Prop({required: true}) private readonly size!: string;
    @Prop({required: false}) private readonly item!: any;
    @Prop({required: false}) private readonly tooltip!: any;

    get isDisabled(): boolean {
        return this.tooltipText === "";
    }

    get tooltipText(): string {
        if (this.tooltip)
            return this.tooltip;
        else if (this.item && this.item[1])
            return this.item[1].name;
        else return ""
    }
}
