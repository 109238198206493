













import { Component, Vue } from 'vue-property-decorator'
import {LolModuleGetterTypes, ChampsData} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import { Getter } from "vuex-class";
import ChampSheet from "./UniqueChamps/ChampSheet.vue"

@Component({
    components: {ChampSheet}
})
export default class UniqueChamps extends Vue {
    @Getter(LolModuleGetterTypes.CHAMPIONS_DATA, { namespace: StoreNamespace.Lol })
    private readonly champsData!: ChampsData;
}
