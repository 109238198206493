





























import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import { CHAMPION_SCORE, ICON, RATING_ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import { COLORS } from '@/core/helpers/color.types';
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISkillFeature} from "./types"
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    enums: {ICON, COLORS, RATING_ICON},
    components: {Tooltip}
})
export default class FeatureInfo extends Vue {
    @Prop({required: true}) private readonly feature!: ISkillFeature;
    private sparklineLabels = ["min", "max"];

    get maxRating(): number {
        return LeagueOfLegendsConstants.maxScoreRating;
    }

    get featureName(): string {
        return this.feature.skill.toUpperCase();
    }

    get skill(): CHAMPION_SCORE {
        return this.feature.champion_score;
    }

    get featureRange(): [number, number] {
        return [this.feature.value1, this.feature.value2];
    }

    get tooltipText(): string {
        const {value, value1, value2} = this.feature;
        return `${value} -> [${value1}, ${value2}]`
    }
}

