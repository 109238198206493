











import { Component, Vue } from 'vue-property-decorator'
import { LolModuleGetterTypes } from "@/store/modules/lol/types";
import { StoreNamespace } from "@/store/types"
import { Getter } from "vuex-class";
import { COLORS } from "@/core/helpers/color.types"

@Component({
    enums: {COLORS}
})
export default class ScoreTracer extends Vue {
    @Getter(LolModuleGetterTypes.MESSAGE, { namespace: StoreNamespace.Lol })
    private readonly msgData!: {statusCode?: number, message?: string} | undefined

    get statusCode(): number | undefined {
        return this.msgData ? this.msgData.statusCode : undefined;
    }

    get message(): string | undefined {
        const statusCode: number | undefined = this.msgData?.statusCode;

        switch(statusCode) {
            case 404:   return 'no-summoner';
            case 403:   return 'no-api-key';
        }
        return 'unknown';
    }

    get isError(): boolean {
        return this.statusCode != undefined && this.statusCode > 0;
    }
}
