





















import Characteristics from './BasicCharacteristics.vue'
import { LolStoreModule } from "@/store"
import { Component, Prop, Vue} from 'vue-property-decorator'
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import {Getter} from "vuex-class";
import { LolDataDragonAssetsApi } from '@/services/RiotAssets/LolDataDragonAssetsApi';
import { Timing } from '@/core/helpers/Timing';
import BaseButtonRounded from '@/components/Shared/Buttons/BaseButtonRounded.vue'
import { IRankedInfo } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { COLORS } from '@/core/helpers/color.types';

@Component({
    components: {Characteristics, BaseButtonRounded},
    enums: { COLORS }
})
export default class SummonerDemo extends Vue {
    @Prop({ type: Number, required: true }) private readonly profileIconId!: number;
    @Prop({ type: String, required: true }) private readonly name!: string;

    @Getter(LolModuleGetterTypes.SUMMONER_RANK_SOLO, { namespace: StoreNamespace.Lol })
    private readonly soloRank!: IRankedInfo
    
    @Getter(LolModuleGetterTypes.SUMMONER_RANK_FLEX, { namespace: StoreNamespace.Lol })
    private readonly flexRank!: IRankedInfo

    @Getter(LolModuleGetterTypes.UPDATE_TIME, { namespace: StoreNamespace.Lol })
    private readonly updateTime!: number
    
    private ProfileIconUri(iconID: number) {
        return LolDataDragonAssetsApi.profileIconUri(iconID);
    }

    private fetchSummonerForce() {
        const name: string = this.$route.params.name;
        const region: string = this.$route.params.region;
        const forced: number = 1;
        LolStoreModule.fetchSummoner({name, region, forced});
    }

    get lastUpdateDateStr(): string | undefined {
        const lastUpdate = this.updateTime;
        if (lastUpdate === -1)      return undefined;
        return Timing.localTimePmStr(lastUpdate, 'DD MMM hh:mm:ss');
    }
}
