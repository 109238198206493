export enum RATING_ICON {
    EMPTY = "mdi-star-outline",
    HALF = "mdi-star-half-full",
    FULL = "mdi-star"
}

export enum DETAILS_COLUMN {
    SCORE = "Individual Champion Skill",
    IMPACT = "Impact",
    BUILD = "Build",
    FIGHT = "Fight",
    GOLD = "Gold",
    SUMMONER = "Summoner"
}

export enum INVALID_POSITION {
    BOT = "bot",
    AFK = "afk",
    INVALID = "invalid"
}

export enum LOL_PARTICIPANT_LABEL {
    MVP = 0,
    FIRST_BLOOD = 1,
    FIRST_BLOOD_ASSIST = 2,
    PENTA_KILL = 3,
    QUADRA_KILL = 4,
    TRIPLE_KILL = 5,
    DOUBLE_KILLS = 6,
    DOUBLE_KILL = 7,
}

export enum COMPRASION {
    EQUAL,
    MORE,
    LESS
}

export enum DRAFT_RESULT {
    EQUAL,
    WIN,
    LOSE
}

export enum ICON {
    SAD = "mid-emoticon-sad-outline",
    DOG = "mdi-dog",
    CHECK = "mdi-check",
    CHECK_ALL = "mdi-check-all",
    MUSTACHE = "mdi-mustache",
    FLASK = "mdi-flask",
    EQUAL = 'mdi-approximately-equal',
    MORE = 'mdi-delta',
    LESS = 'mdi-details',
    SKULL = 'mdi-skull',
    EYE = 'mdi-eye',
    FLAG = 'mdi-flag-outline',
    SEARCH = 'mdi-magnify',
    KDA = 'mdi-sword-cross',
    DAMAGE = 'mdi-bomb',
    KILL = 'mdi-skull',
    DOT_SEPARATOR = 'mdi-circle-small',
    WNR = 'mdi-chart-line-variant',
    SCORE = 'mdi-microsoft-xbox-controller',
    FEATURE_DEFAULT = 'mdi-bullseye-arrow'
}

export enum SCORE_STR_LABEL {
    poor = "poor",
    good = "good",
    excellent = "excellent"
}

export enum PLAYER_IMPACT {
    UNKNOWN = 0,
    TROLL = 1,
    GG = 2,
    CARRY = 3,
    SMURF = 4
}

export enum CHAMPION_SCORE {
    NOT_AVAILABLE = 0,
    POOR = 1,
    GOOD = 2,
    EXCELLENT = 3,
}

export enum GRAPH_TREND {
    RISING,
    DECLININING,
    STABLE
}

export enum RANK {
    UNRANKED = "unranked"
}

export enum MAIN_CHAMP_ENUM {
    MIXED = "Mixed",
    UNKNOWN = "Unknown"
}

export enum URI_POSITION {
    MID = "Mid",
    BOT = "Bot",
    SUPPORT = "Support",
    JUNGLE = "Jungle",
    TOP = "Top",
    MIXED = "Mixed",
    UNKNOWN = "Unknown"
}

export enum IndividualPosition {
    BOTTOM = 'BOTTOM',
    TOP = 'TOP',
    JUNGLE = 'JUNGLE',
    MIDDLE = 'MIDDLE',
    UTILITY = 'UTILITY',
    MIXED = 'MIXED',
    UNKNOWN = 'UNKNOWN'
}
