














import { Component, Vue, Prop } from 'vue-property-decorator'
import { ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip}
})
export default class KdaInfo extends Vue {
    private readonly icon: ICON = ICON.FLASK;

    @Prop({required: true}) private readonly value!: string;
    @Prop({required: true}) private readonly summonerName!: string;
}
