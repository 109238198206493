





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ILolMatch, ILolMatchParticipant } from '@/core/games/leagueOfLegends/leagueOfLegends.types'
import { CHAMPION_SCORE, ICON, INVALID_POSITION, RATING_ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums'
import { COLORS } from '@/core/helpers/color.types'
import { Getter } from 'vuex-class'
import { LolModuleGetterTypes } from '@/store/modules/lol/types'
import { StoreNamespace } from '@/store/types'
import { LolDataDragonAssetsApi } from '@/services/RiotAssets/LolDataDragonAssetsApi'
import { IQueueData } from '@/services/RiotAssets/types'
import { Timing } from '@/core/helpers/Timing'
import { LolMatchTools, PlayerMetadata } from '@/calculations/leagueOfLegends/LolMatchTools'
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends'
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans'
import ItemsNSpells from './ItemsNSpells.vue'
import DotSeparatedText from '@/components/Shared/DotSeparatedText.vue'

@Component({
    enums: {COLORS, ICON, RATING_ICON},
    components: { ItemsNSpells, DotSeparatedText }
})
export default class MatchHeader extends Vue {
    @Prop({required: true}) private readonly match!: ILolMatch;
    @Prop({required: true}) private readonly wasRemake!: boolean;
    @Prop({required: true}) private readonly player!: ILolMatchParticipant;
    @Prop({required: true}) private readonly playersPerformance!: Map<number, PlayerMetadata>;

    private readonly NO_KILLS_MSG: string = "no kills";

    @Getter(LolModuleGetterTypes.QUEUE_DATA, { namespace: StoreNamespace.Lol })
    private readonly queueData!: IQueueData[] | undefined;

    get subtitleItems(): any[] {
        return [
            {icon: undefined, text: this.matchDateStr},
            {icon: undefined, text: this.getQueueName},
            {icon: ICON.KDA, text: `K/D/A: ${this.kda}`},
        ]
    }

    get maxRating(): number {
        return LeagueOfLegendsConstants.maxScoreRating;
    }

    get summonerPerformance(): number | undefined {
        return this.playersPerformance.get(this.player.participantId)?.performance;
    }

    get avgFeatureScore(): string {
        const score: number | undefined = this.playersPerformance.get(this.player.participantId)?.avgFeatureScore;
        return score ? `(${score})` : '';
    }

    get rolePLayed(): string {
        let position: string = this.player.individualPosition;
        return `${position}`;
    }

    get matchDateStr(): string {
        const timestamp = this.match.data.info.gameCreation;
        return Timing.localTimePmStr(timestamp);
    }
    
    get endedWithSurrender(): boolean {
        return !this.wasRemake && (this.player.gameEndedInEarlySurrender || this.player.gameEndedInSurrender);
    }

    get winInfoColor(): string {
        return !this.player.win ? COLORS.RED : COLORS.GREEN;
    }

    get getQueueName(): string {
        const queue = this.queueData!.find((queue: IQueueData) => {
            return queue.queueId === this.match.data.info.queueId;
        });

        if (queue && queue.description)  return queue.description;           
        return this.match.data.info.gameMode;
    }
    
    get championPlayed(): string {
        return this.player ? this.player.championName : "";
    }

    get kda(): string {
        const ingameSummoner = this.player;
        return `${ingameSummoner.kills} / ${ingameSummoner.deaths} / ${ingameSummoner.assists}`
    }

    get killParticipation(): string {
        const ingameSummoner = this.player;
        const teamKills = LolMatchTools.getTeamKills(ingameSummoner.win, this.match);

        if (!teamKills) return this.NO_KILLS_MSG;
        else return `${LeagueOfLegendsUtils.formatKpp((ingameSummoner.kills + ingameSummoner.assists) / teamKills)}%`;
    }


    private championIconUri(champion: string): string {
        return LolDataDragonAssetsApi.championIconUri(champion);
    }
}
