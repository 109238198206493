









import { Component, Vue ,Prop } from 'vue-property-decorator'
import { LolModuleGetterTypes } from "@/store/modules/lol/types";
import { StoreNamespace } from "@/store/types"
import { Getter } from "vuex-class";
import { ICharacteristics, ISummoner } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import { CHAMPION_SCORE, SCORE_STR_LABEL } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';

@Component({
})
export default class GraphDesc extends Vue {
    @Prop({required: true}) private readonly graphTrendInfo!: string;
    @Prop({required: true}) private readonly wnrMatchesN!: string;
    @Prop({required: true}) matchAvgCalcRange!: number;
    
    @Getter(LolModuleGetterTypes.SUMMONER, { namespace: StoreNamespace.Lol })
    private readonly summoner!: ISummoner
    @Getter(LolModuleGetterTypes.MATCHES_FIRST_FETCH_N, { namespace: StoreNamespace.Lol })
    private readonly matchesN!: number
    @Getter(LolModuleGetterTypes.CHARACTERISTIC, { namespace: StoreNamespace.Lol })
    private readonly characteristic!: ICharacteristics
    
    get wnrNumber(): number {
        const wnrChar = this.characteristic ? this.characteristic.wnr_characteristic : 0;
        if (wnrChar) 
            return Math.round(wnrChar.avg_wnr * 100);
        return 0;
    }
    get wnrRate(): string {
        const wnrChar = this.characteristic ? this.characteristic.wnr_characteristic : 0;
        if (wnrChar) 
            return wnrChar.label;
        return "";
    }

    get wnrRateKey(): CHAMPION_SCORE | undefined {
        const rate = this.wnrRate.toLowerCase() as SCORE_STR_LABEL;
        return LeagueOfLegendsConstants.skillLabelToScoreMap.get(rate);
    }
}
