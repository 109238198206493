import { ISkillFeature } from "@/components/stats/lol/ScoreTracer/UniqueChamps/types";
import { DRAFT_RESULT, LOL_PARTICIPANT_LABEL } from "@/core/games/leagueOfLegends/leagueOfLegends.enums";
import { ILolMatch, ILolMatchParticipant } from "@/core/games/leagueOfLegends/leagueOfLegends.types";
import { IPerkData, IRuneData, ISummonerSpell } from "@/services/RiotAssets/types";

export interface PlayerMetadata {
    performance: number | undefined,
    matchupScore: number,
    avgFeatureScore: number
}

export interface TeamsPuuids {
    allyTeam: Array<number>,
    enemyTeam: Array<number>
}

export interface KDA {
    kills: number,
    deaths: number,
    assists: number
}

export interface LabelArrayItem {
    type: LOL_PARTICIPANT_LABEL,
    value?: string
}

export class LolMatchTools {
    private constructor() {}

    public static formatAvgFeatureScore(featureScoreSum: number, featuresN: number) : number {
        const avg = featureScoreSum / featuresN;
        const intPart = Math.floor(avg);
        let fractionPart = avg - intPart;
        fractionPart = Math.round(fractionPart * 10) / 10;
        return intPart + fractionPart;
    }

    public static getTeamKills(winnerTeam: boolean, matchData: ILolMatch) {
        if (!matchData)    return 0;
        let totalteamkills = 0;
        
        for (const player of matchData.data.info.participants) {
            if (player.win === winnerTeam) {
                totalteamkills += player.kills;
            }
        }
        return totalteamkills;
    }

    public static calculateChampionPerformance(championSkillFeatures: Array<ISkillFeature>): number | undefined {
        let performanceSum: number = 0;
        for (const feature of championSkillFeatures) {
            performanceSum += feature.champion_score;
        }
        if (!championSkillFeatures.length)
            return undefined
        return performanceSum / championSkillFeatures.length;
    }

    public static calculateAvgTeamPerformance(teamParticipantIds: Array<number>, perfMap: Map<number, PlayerMetadata>): number {
        let sum = 0;
        let divider = teamParticipantIds.length;
        for (const id of teamParticipantIds) {
        
            const playerMeta: PlayerMetadata | undefined = perfMap.get(id);
            if (playerMeta && playerMeta.performance)
                sum += playerMeta.performance;
            else divider--;
        }
        if (divider < 1)   return 0;
        else return sum / divider
    }

    public static sumDraftScore(teamPuuids: Array<number>, perfMap: Map<number, PlayerMetadata>): number {
        let sum: number = 0;
        for (const playerPuuid of teamPuuids) {
        
            const score: PlayerMetadata | undefined = perfMap.get(playerPuuid);
            if (!score || !score.matchupScore) continue;
            sum += score.matchupScore; 
        }
        return sum;
    }
    
    public static rateDraftScores(draftScores: [number, number]): DRAFT_RESULT {
        if (draftScores[0] === draftScores[1])       return DRAFT_RESULT.EQUAL;
        else if (draftScores[0] > draftScores[1])    return DRAFT_RESULT.WIN;
        else                                         return DRAFT_RESULT.LOSE;
    }
    
    public static getTeamKdaSum(teamIds: Array<number>, matchData: any): KDA {
        let result: KDA = {
            kills:0, deaths: 0, assists: 0
        };
    
        for (const id of teamIds) {
            
            const player = matchData.data.info.participants.find((player: any) => {
                return player.participantId === id;
            });
            if (!player)    continue;
            result.kills += player.kills;
            result.assists += player.assists;
            result.deaths += player.deaths;
        }
    
        return result;
    }

    public static calculatePlayerPerformanceMap(matchData: ILolMatch): Map<number, PlayerMetadata> {
        const resultMap = new Map<number, PlayerMetadata>();

        for(const gameAccount of matchData.game_account_matches) {
            const performance = gameAccount.champion_skill_score;

            let featureScoreSum = 0;
            for (let i = 0; i < gameAccount.champion_skill_features.length; ++i)
                featureScoreSum += gameAccount.champion_skill_features[i].champion_score;

            resultMap.set(gameAccount.participant_id, {
                performance: performance,
                matchupScore: gameAccount.matchup_score,
                avgFeatureScore: gameAccount.champion_skill_features.length === 0 ? 0 : LolMatchTools.formatAvgFeatureScore(featureScoreSum, gameAccount.champion_skill_features.length)
            });
        }
        return resultMap;
    }

    public static getLabels(player: ILolMatchParticipant, mvpId: number): LabelArrayItem[]{
        const resultArray: Array<LabelArrayItem> = [];

        if (mvpId === player.participantId)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.MVP});
        if (player.firstBloodKill)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.FIRST_BLOOD});
        if (player.firstBloodAssist)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.FIRST_BLOOD_ASSIST});
        if (player.pentaKills > 0)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.PENTA_KILL});
        if (player.quadraKills > 0)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.QUADRA_KILL});
        if (player.tripleKills > 0)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.TRIPLE_KILL});
        if (player.doubleKills > 1)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.DOUBLE_KILLS, value: player.doubleKills.toString()});
        else if (player.doubleKills > 0)
            resultArray.push({type: LOL_PARTICIPANT_LABEL.DOUBLE_KILL});

        return resultArray;
    }

    public static getSummonerSpell(spellId: string, spellsArray: Array<[string, ISummonerSpell]>): ISummonerSpell | undefined {
        if (!spellsArray)   return undefined;
        for (const pair of spellsArray) {
            const spell: ISummonerSpell = pair[1];
            if (spell.key === spellId)
                return spell;
        }
        return undefined;
    }

    public static getMainStyle(player: ILolMatchParticipant, runesData: IRuneData[]): IRuneData | undefined {
        const styleId: number = player.perks.styles[0].style;
        let rune: IRuneData | undefined = undefined;
        for (const style of runesData) {
            if (style.id === styleId) {
                rune = style;
            }
        }
        return rune;
    }

    public static getMainPerk(player: ILolMatchParticipant, mainStyle: IRuneData | undefined): IPerkData | undefined {
        const runeId: number = player.perks.styles[0].selections[0].perk;
        if (!mainStyle) return undefined;
        for (const slot of mainStyle.slots) {
            for (const rune of slot.runes) {
                if (rune.id === runeId) {
                    return rune;
                }
            }
        }
        return undefined;
    }
}
