















import { IGraphRecordData, MovingAvg } from '@/calculations/common/MovingAvg'
import { LolDataDragonAssetsApi } from '@/services/RiotAssets/LolDataDragonAssetsApi'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { COLORS } from "@/core/helpers/color.types"
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    enums: { COLORS }
})
export default class GraphRecord extends Vue {
    @Prop({required: true}) recordData!: IGraphRecordData;
    @Prop({required: true}) maxGraphHeight!: number;
    
    private readonly winColor = COLORS.SECONDRAY_LIGHT;
    private readonly loseColor = COLORS.SECONDARY_DARK;
    private avgRange: number = MovingAvg.lastMovingAvgRange;
    
    get riseColor(): string {
        return this.recordData.isPositive() ? this.winColor : this.loseColor;
    }
    
    private champIconUri(championName: string) {
        return LolDataDragonAssetsApi.championIconUri(championName);
    }
}
