










import {Getter} from "vuex-class";
import {LolModuleGetterTypes} from "@/store/modules/lol/types";
import {StoreNamespace} from "@/store/types"
import { Component, Prop, Vue} from 'vue-property-decorator'
import LvlChar from './BasicCharacteristics/LvlChar.vue'
import RoleChar from './BasicCharacteristics/RoleChar.vue'
import ChampChar from './BasicCharacteristics/ChampChar.vue'
import WnrChar from './BasicCharacteristics/WnrChar.vue'
import SoloRankChar from './BasicCharacteristics/SoloRankChar.vue'
import FlexRankChar from './BasicCharacteristics/FlexRankChar.vue'
import { IChampionCharacteristic, ICharacteristics, ILevelCharacteristic, IPositionCharacteristic, IRankedInfo } from "@/core/games/leagueOfLegends/leagueOfLegends.types";

@Component({
    components: {LvlChar, RoleChar, ChampChar, WnrChar, SoloRankChar, FlexRankChar}
})
export default class Characteristics extends Vue {
    @Prop({ type: Number, required: true, default: () => 0 }) private readonly profileIconId!: number;

    @Getter(LolModuleGetterTypes.CHARACTERISTIC, { namespace: StoreNamespace.Lol })
    private readonly Characteristic!: ICharacteristics
    
    @Getter(LolModuleGetterTypes.SUMMONER_RANK_SOLO, { namespace: StoreNamespace.Lol })
    private readonly soloRank!: IRankedInfo
    
    @Getter(LolModuleGetterTypes.SUMMONER_RANK_FLEX, { namespace: StoreNamespace.Lol })
    private readonly flexRank!: IRankedInfo

    get levelCharacteristic(): ILevelCharacteristic | undefined {
        if (this.Characteristic)
            return this.Characteristic.level_characteristic;
        return undefined;
    }
    get positionCharacteristic(): IPositionCharacteristic | undefined {
        if (this.Characteristic)
            return this.Characteristic.individual_position_characteristic;
        return undefined;
    }
    get championCharacteristic(): IChampionCharacteristic | undefined {
        if (this.Characteristic)
            return this.Characteristic.champion_characteristic;
        return undefined;
    }
    get wnrCharacteristic(): {avg_wnr: number, label: string} | undefined {
        if (!this.Characteristic)    return undefined;
        const {matches, ...wnrChars} = this.Characteristic.wnr_characteristic;
        return wnrChars;
    }
}
