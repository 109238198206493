







import { COMPRASION, DRAFT_RESULT, ICON } from '@/core/games/leagueOfLegends/leagueOfLegends.enums';
import { COLORS } from '@/core/helpers/color.types';
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends';
import { Component, Vue, Prop } from 'vue-property-decorator'
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip}
})    
export default class TeamDraft extends Vue {
    @Prop({required: true}) private readonly icon!: ICON;
    @Prop({required: true}) private readonly label!: DRAFT_RESULT;
    @Prop({required: true}) private readonly summonerName!: string;
    @Prop({required: true}) private readonly comprasionResult!: COMPRASION;

    get iconColor(): COLORS {
        return LeagueOfLegendsUtils.getIconColor(this.icon);
    }
}
