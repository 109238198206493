











import GraphDesc from '@/components/stats/lol/ScoreTracer/WnrGraph/GraphDesc.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({components: {GraphDesc}})
export default class GraphRecord extends Vue {
    @Prop({required: true}) wnrNumber!: number;
    @Prop({required: true}) matchAvgCalcRange!: number;
}

