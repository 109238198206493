









import { Component, Vue, Prop } from 'vue-property-decorator'
import { ILolMatch, ILolMatchParticipant } from '@/core/games/leagueOfLegends/leagueOfLegends.types'
import { ICON, CHAMPION_SCORE, INVALID_POSITION, PLAYER_IMPACT, DRAFT_RESULT } from '@/core/games/leagueOfLegends/leagueOfLegends.enums'
import { LabelArrayItem, LolMatchTools, PlayerMetadata } from '@/calculations/leagueOfLegends/LolMatchTools'
import ItemsNSpells from './ItemsNSpells.vue'
import Label from './Label.vue'
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends';
import { LeagueOfLegendsConstants } from '@/calculations/leagueOfLegends/constans';
import ImpactInfo from "@/components/stats/lol/ScoreTracer/match/PlayerMetaInfo/ImpactInfo.vue"
import KillParticipationInfo from "@/components/stats/lol/ScoreTracer/match/PlayerMetaInfo/KillParticipationInfo.vue"
import KdaInfo from "@/components/stats/lol/ScoreTracer/match/PlayerMetaInfo/KdaInfo.vue"
import Tooltip from "@/components/Core/Tooltip.vue"
import TeamDraft from '@/components/stats/lol/ScoreTracer/match/TeamStats/TeamDraft.vue'
import Teamwork from '@/components/stats/lol/ScoreTracer/match/TeamStats/Teamwork.vue'
import TeamChampPerformance from '@/components/stats/lol/ScoreTracer/match/TeamStats/TeamChampPerformance.vue'

@Component({
    components: { Tooltip, ItemsNSpells, Label, KdaInfo, KillParticipationInfo, ImpactInfo, TeamDraft, TeamChampPerformance, Teamwork },
    enums: { ICON }
})
export default class PlayerMatchStats extends Vue {
    private readonly NO_KILLS_MSG: string = "no kills";

    @Prop({required: true}) private readonly playersPerformance!: Map<number, PlayerMetadata>;
    @Prop({required: true}) private readonly player!: ILolMatchParticipant;
    @Prop({required: true}) private readonly allyMvpParicipantId!: number;
    @Prop({required: true}) private readonly match!: ILolMatch;
    @Prop({required: true}) private readonly avgTeamPerformance!: {ally: number, enemy: number};
    @Prop({required: true}) private readonly teamDraftScores!: [number, number];
    @Prop({required: true}) private readonly teamworkScores!: [number, number];


    private getComprasionPhrase(a: number, b: number): DRAFT_RESULT {
        return LolMatchTools.rateDraftScores([a, b])
    }

    private getComprasionIcon(value1: number, value2: number) {
        return LeagueOfLegendsUtils.getComprasionIcon(value1, value2);
    }

    get draftScore() {
        return LolMatchTools.rateDraftScores(this.teamDraftScores);
    }

    get performanceRating(): CHAMPION_SCORE | undefined {
        return LeagueOfLegendsUtils.mapValueToLabel(this.avgTeamPerformance.ally, LeagueOfLegendsConstants.skillScoreToLabelMap);
    }

    get teamworkScore(): CHAMPION_SCORE | undefined {
        return LeagueOfLegendsUtils.mapValueToLabel(this.teamworkScores[0], LeagueOfLegendsConstants.teamworkScoreToLabelMap)
    }

    get summonerPerformanceGrade(): CHAMPION_SCORE | undefined{
        const playerMeta: PlayerMetadata | undefined = this.playersPerformance.get(this.player.participantId);
        return playerMeta?.performance;
    }

    get summonerName(): string {
        return this.player ? this.player.summonerName : "";
    }

    get rolePLayed(): string {
        let position: string = this.player.individualPosition;
        if (!position) return "";
        position = position.toLowerCase();
        if (position === INVALID_POSITION.BOT || position === INVALID_POSITION.AFK || position === INVALID_POSITION.INVALID) {
            return "";
        }
        return `${this.player.championName} ${position}`;
    }

    get playerLabels(): LabelArrayItem[] {
        return LolMatchTools.getLabels(this.player, this.allyMvpParicipantId);
    }

    get killParticipation(): string {
        const ingameSummoner = this.player;
        const teamKills = LolMatchTools.getTeamKills(ingameSummoner.win, this.match);

        if (!teamKills) return this.NO_KILLS_MSG;
        else return `${LeagueOfLegendsUtils.formatKpp((ingameSummoner.kills + ingameSummoner.assists) / teamKills)}%`;
    }

    get kda(): string {
        const ingameSummoner = this.player;
        return `${ingameSummoner.kills} / ${ingameSummoner.deaths} / ${ingameSummoner.assists}`
    }

}
