



















import InfoSquareMixin from './InfoSquareMixin.vue'
import { Component, Vue, Prop} from 'vue-property-decorator'
import { IWnrCharacteristic } from '@/core/games/leagueOfLegends/leagueOfLegends.types';
import { LeagueOfLegendsUtils } from '@/core/games/leagueOfLegends/leagueOfLegends';
import Tooltip from "@/components/Core/Tooltip.vue"

@Component({
    components: {Tooltip},
    mixins: [InfoSquareMixin]
})
export default class WnrSquare extends Vue {
    @Prop() private readonly dataInfo!: IWnrCharacteristic;

    get wnr(): string {
        if (this.dataInfo && this.dataInfo.avg_wnr)
            return `${LeagueOfLegendsUtils.formatWnr(this.dataInfo.avg_wnr)}%`;
        return "";
    }
}
